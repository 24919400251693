class TermPolicy {
    constructor(doc) {
        this.id = doc.id;
        this.description_it = doc.termini_e_condizioni_it;
        this.description_en = doc.termini_e_condizioni;
    }

    toString() {
        return `id: ${this.id}`;
    }
}

export const termconverter = {
    toFirestore: (term) => {
        return {
            description_it: term.termini_e_condizioni_it,
            description_en: term.termini_e_condizioni,
        }
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new TermPolicy(data);
    }
};