import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

// Configurazione Firebase
const firebaseConfig = {

  // apiKey: "AIzaSyA0Hra5OfP6tWVVSpElQWpoqYXnHJwzePc",
  // authDomain: "arshadesstaging.firebaseapp.com",
  // databaseURL: "https://arshadesstaging-default-rtdb.europe-west1.firebasedatabase.app",
  // projectId: "arshadesstaging",
  // storageBucket: "arshadesstaging.appspot.com",
  // messagingSenderId: "251516419347",
  // appId: "1:251516419347:web:98a76f24b00739efd26ee6",
  // measurementId: "G-9PTM5X8CGH"
  apiKey: "AIzaSyCDQNqwJw25FReWScxp2NjMSNUF_NBBDPQ",
  authDomain: "arshades-7e18a.firebaseapp.com",
  databaseURL: "https://arshades-7e18a.firebaseio.com",
  projectId: "arshades-7e18a",
  storageBucket: "arshades-7e18a.appspot.com",
  messagingSenderId: "1096373281553",
  appId: "1:1096373281553:web:12b6349a72b7be3600a2b0",
  measurementId: "G-562Q4WEHJY",
};

// Inizializzazione Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
