import './Accordion.css';

function Accordion({ panel, openPanel, handlePanelChange, persistOpened, children, title }) {
  const isOpen = openPanel === panel;
  const isPersisted = persistOpened.includes(panel);


    return (
      <div className={`accordion-container ${isOpen ? 'expanded' : ''}`}>
        <div className="accordion-header" onClick={() => handlePanelChange(panel)}>
          <div className="accordion-title-wrapper">
            {/* Aggiungi la classe condizionale se il pannello è aperto o già aperto una volta */}
            <p className={`accordion-title ${isOpen || isPersisted ? 'active-title' : ''}`}>{title}</p>
            <p className={`accordion-read ${isOpen || isPersisted ? 'active-read' : ''}`}>
              READ
              {/* Aggiungi la classe condizionale per la freccia */}
              <span className={`accordion-arrow ${isOpen || isPersisted ? 'active-arrow' : ''} ${isOpen ? 'rotate' : ''}`}></span>
            </p>
          </div>
        </div>
        {isOpen && <div className="accordion-content">{children}</div>}
      </div>
    );
  

  // if (TEMPLATE === 'Button Template Vanilla 2') {
  //   return (
  //     <div className="v2-accordion-close" onClick={() => handlePanelChange(panel)}>
  //       <div className="v2-flex">
  //         <div className="v2-left-accordion-policy">
  //           <p className="v2-title_policy_accordion" onClick={() => handlePanelChange(panel)}>{title}</p>
  //         </div>
  //         <div className="v2-right-accordion-policy">
  //           <p className="v2-read-policy" onClick={() => handlePanelChange(panel)}>
  //             READ
  //             <span className={`v2-arrow-icon ${isOpen ? 'rotate' : ''}`}></span>
  //           </p>
  //         </div>
  //       </div>
  //       {isOpen && children}
  //     </div>
  //   );
  // }
}

export default Accordion;