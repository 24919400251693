import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { doc, getDoc } from "@firebase/firestore";
import { termconverter } from "../modello/Terms";
import { db } from "../data/base";
import Accordion from "../component/Accordion";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import "./privacyPolicy.css";

const PrivacyPolicy = () => {
  const [searchParams] = useSearchParams();
  const mirror = searchParams.get('mirror');
  const terms = searchParams.get('terms');
  
  const [openPanel, setOpenPanel] = useState(0);
  const [persistOpened, setPersistOpened] = useState([]);
  
  // Stato per il contenuto Markdown della Privacy Policy
  const [policyMarkdown, setPolicyMarkdown] = useState('');
  
  // Stato per i Terms and Conditions caricati da Firestore
  const [termsAndCondition, setTermAndCondition] = useState();
  
  const [readPolicy, setRead] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (!readPolicy) {
        // Carica i Terms and Conditions da Firestore
        const docInitTerms = doc(db, "TermsAndCondition", "uhOAuuHlosGyYbzdjXDW").withConverter(termconverter);
        const initDocTerms = await getDoc(docInitTerms);
        const initTermsDoc = initDocTerms.data();
        console.log(initTermsDoc);
        setTermAndCondition(initTermsDoc);

        // Carica il file Markdown per la Privacy Policy
        fetch('/content/pp3.md')
          .then(response => response.text())
          .then(text => setPolicyMarkdown(text))
          .catch(error => console.error("Errore nel caricamento del file markdown:", error));

        setRead(true);
      }
    };

    loadData();
  }, [mirror, readPolicy]);

  const handlePanelChange = (panel) => {
    setOpenPanel((prev) => (prev === panel ? 0 : panel)); // Apri/chiudi il pannello

    // Aggiunge il pannello all'elenco dei pannelli aperti in modo persistente
    setPersistOpened((prev) => {
      if (!prev.includes(panel)) {
        return [...prev, panel];
      }
      return prev;
    });
  };

  return (
    <div className="permission_policy">
      <div className="header">
        <div className="logo">
          <img
            src="/asset/Safilo_Logo.svg"
            alt="Safilo Logo"
            className="logo-image"
          />
        </div>
        <h3 className="snapshot-subtitle">VTO Mirror</h3>
      </div>

      <div className="title"></div>

      {/* Wrapper per il contenuto degli Accordion */}
      <div className="accordion-wrapper">
        <Accordion
          panel={1}
          openPanel={openPanel}
          handlePanelChange={handlePanelChange}
          persistOpened={persistOpened}
          title={"Terms and Condition"}
        >
          <div className="policy-open">
            {termsAndCondition?.description_en.map((paragraph, index) => (
              <div key={index}>
                <h3 className="title-policy">{paragraph.titolo}</h3>
                <p className="paragrafo-policy">{paragraph.paragrafo}</p>
              </div>
            ))}
          </div>
        </Accordion>

        <Accordion
          panel={2}
          openPanel={openPanel}
          handlePanelChange={handlePanelChange}
          persistOpened={persistOpened}
          title={"Privacy Policy"}
        >
          <div className="policy-open">
            <ReactMarkdown  remarkPlugins={[remarkGfm]}>{policyMarkdown}</ReactMarkdown>
          </div>
        </Accordion>
      </div>

      <div
        className="powered-by-text"
        style={{
          textAlign: "center",
          fontSize: "15px",
          fontWeight: "400",
          marginTop: "20px",
          color: "var(--primary-color-th)",
          position: "fixed",
          zIndex: "9999",
          bottom: "5%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        Powered by{" "}
        <a
          href="https://arshades.it"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "var(--primary-color-th)",
            textDecoration: "underline",
            fontWeight: "400",
          }}
        >
          ARShades
        </a>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
