import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ref as storageRef, getDownloadURL , deleteObject} from "firebase/storage";
import { storage } from "../data/base";
import "./snapshot.css"; // Import del file CSS

const Snapshot = () => {
  const [searchParams] = useSearchParams();
  const [imageUrl, setImageUrl] = useState(null);
  const [isLandscape, setIsLandscape] = useState(false);
  const [message, setMessage] = useState("");

  // Privacy policy param
  const privacyPolicy = "apVjQIUjGKR2BdDmlihh";

  useEffect(() => {
    const lmSnapshot = searchParams.get("Ln_snapshot"); // Param per Landscape
    const prSnapshot = searchParams.get("Pr_snapshot"); // Param per Portrait
    const snapshotParam = lmSnapshot || prSnapshot;

    const fetchImage = async () => {
      if (snapshotParam) {
        try {
          // Scegli la cartella in base a quale param esiste
          const folder = lmSnapshot ? "LN_Mirror_Image" : "PR_Mirror_Image";
          const imageRef = storageRef(
            storage,
            `/Mirror/Snapshot/${folder}/${snapshotParam}`
          );
          const url = await getDownloadURL(imageRef);

          // Imposta l’URL
          setImageUrl(url);

          // Se vuoi determinare l’orientamento *davvero* in base al ratio dell'immagine
          const img = new Image();
          img.src = url;
          img.onload = () => {
            if (img.width > img.height) {
              setIsLandscape(true);
            } else {
              setIsLandscape(false);
            }
          };
        } catch (error) {
          console.error("Errore nel recupero dell'immagine:", error);
        }
      }
    };

    fetchImage();
  }, [searchParams]);

  // Funzione per condividere
  const handleShare = async () => {
    if (navigator.share && navigator.canShare && imageUrl) {
      try {
        // Fetch dell'immagine come blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();
  
        // Creazione di un oggetto file
        const file = new File([blob], "snapshot.jpg", { type: blob.type });
  
        // Verifica se il browser può condividere i file
        if (navigator.canShare({ files: [file] })) {
          // Condivisione del file
          await navigator.share({
            title: "Check out my picture!",
            text: "Here's the snapshot I wanted to share.",
            files: [file],
          });
        } else {
          console.error("The browser cannot share files.");
          alert("This browser does not support sharing files.");
        }
      } catch (error) {
        console.error("Error sharing the image:", error);
        alert("An error occurred while sharing the image.");
      }
    } else {
      alert("Sharing is not supported on this browser.");
    }
  };
  

  const handleDelete = async () => {
    if (!imageUrl) {
      setImageUrl(null);
      setMessage("No image to delete.");
      return;
    }
  
    try {
      const lmSnapshot = searchParams.get("Ln_snapshot"); // Param per Landscape
      const prSnapshot = searchParams.get("Pr_snapshot"); // Param per Portrait
      const snapshotParam = lmSnapshot || prSnapshot;
  
      if (snapshotParam) {
        const folder = lmSnapshot ? "LN_Mirror_Image" : "PR_Mirror_Image";
        const imageRef = storageRef(
          storage,
          `/Mirror/Snapshot/${folder}/${snapshotParam}`
        );
  
        // Elimina l'immagine
        await deleteObject(imageRef);
  
        // Aggiorna lo stato per mostrare il messaggio
        setImageUrl(null);
        setMessage("Image deleted successfully.");
      } else {
        setMessage("No valid snapshot parameter found.");
      }
    } catch (error) {
      console.error("Error deleting the image:", error);
      setMessage("An error occurred while deleting the image.");
    }
  };
  

  return (
    <div className="snapshot-container">
      {/* HEADER */}
      <div className="header">
        <div className="logo">
          <img
            src="/asset/Safilo_Logo.svg"
            alt="Safilo Logo"
            className="logo-image"
          />
        </div>
        <h3 className="snapshot-subtitle">VTO Mirror</h3>

        
      </div>

      {/* PHOTO CONTAINER */}
      <div className="photo-container">
      {imageUrl && (

        <h2 className="snapshot-title">Your Picture</h2>
      )}
        {imageUrl ? (
    <div className="image-wrapper">
      <img
        src={imageUrl}
        alt="Snapshot"
        className={`snapshot-image ${isLandscape ? "landscape" : "portrait"}`}
      />
    </div>
  ) : (
    <p className="message">{message || "No valid image available"}</p>
  )}
      </div>

      {/* FOOTER */}
      <div className="footer">

      {imageUrl ? (
        <>
        <button className="button-vanilla" onClick={handleShare}>
        <div className="btn-text-vanilla">
  <img
    src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/Mirror%2FIcon%2Fshare_icon.png?alt=media&token=e4995ba0-ee94-431f-87a3-a272401d285a"
    alt="Share icon"
    className="share-icon"
  />
  <div className="CTA-TEXT-vanilla">SHARE</div>
</div>
        </button>

        <p className="snapshot-info" onClick={handleDelete}>
  Delete this image from our server
</p>
  <p className="snapshot-info-two">Or leave it, and we will delete it today</p>
  </>
): null}  
      </div>
   
      <div className="footer-two">
  <p className="snapshot-info-three">
    <a
      href={`/PrivacyPolicy?mirror=jKYBjtE5h60UcA2xVD8O`}
      className="privacy-link"
    >
      Read our Privacy Policy
    </a>
  </p>
  <p className="powered-by">Powered by ARShades</p>
</div>

    </div>
  );
};

export default Snapshot;
